import React, { useState } from "react"
import { css } from "@emotion/react"
import { Link, graphql } from "gatsby"
import BookLayout from "../components/BookLayout"
import SEO from "../components/bseo"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
import Select from "react-select"
import moment from "moment-timezone"

const stripePromise = loadStripe("pk_live_2jrraR8mH4dj7z68t1jnScmi00wMa7XwwG")
const WPM_AVG = 130

const rateStringMap = {
  daily: "days",
  weekly: "weeks",
  monthly: "months",
}
const inboxOptions = [
  {
    value: "email",
    label: "Email Newsletter",
  },
  {
    value: "rss",
    label: "RSS",
  },
]

const frequencyOptions = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
]

const dayOptions = [
  {
    value: "sunday",
    label: "Sunday",
  },
  {
    value: "monday",
    label: "Monday",
  },
  {
    value: "tuesday",
    label: "Tuesday",
  },
  {
    value: "wednesday",
    label: "Wednesday",
  },
  {
    value: "thursday",
    label: "Thursday",
  },
  {
    value: "friday",
    label: "Friday",
  },
  {
    value: "saturday",
    label: "Saturday",
  },
]

const quantityOptions = [
  {
    value: "chapter",
    label: "1 Chapter",
  },
  {
    value: "wordcount",
    label: "Word Count",
  },
]

const timeOptions = [
  {
    value: "morning",
    label: "Morning (9:00 AM)",
  },
  {
    value: "night",
    label: "Night (9:00 PM)",
  },
]

const wordCountOptions = [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000].map(
  num => ({
    value: `${num}`,
    label: `${num} Words (${Math.ceil(num / WPM_AVG)} minutes)`,
  })
)

const BookPage = ({
  pageContext: {
    bookName,
    bookAuthor,
    bookDescription,
    bookImage,
    bookWordCount,
    bookChapterCount,
    bookHasCustomPrice,
  },
}) => {
  const [inbox, setInbox] = useState(null)
  const [frequency, setFrequency] = useState(null)
  const [day, setDay] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [time, setTime] = useState(null)
  const [wordCount, setWordCount] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")

  const numPeriodsToFinish =
    quantity?.value === "chapter"
      ? bookChapterCount
      : Math.ceil(bookWordCount / Number(wordCount?.value))
  const rateString = `${numPeriodsToFinish} ${rateStringMap[frequency?.value]}`
  const handleClick = async event => {
    setLoading(true)
    if (!inbox || !frequency || !quantity || !email || !time) {
      setLoading(false)
      setError(true)
      return //invalid data
    }
    setError(false)
    // Get Stripe.js instance
    const stripe = await stripePromise

    // Call your backend to create the Checkout Session
    try {
      const response = await axios.post(
        "https://slashblogs.com/create-checkout-session",
        {
          bookTitle: bookName,
          bookDescription: bookDescription,
          bookImage: bookImage,
          bookAuthor: bookAuthor,
          bookHasCustomPrice,
          inbox: inbox.label,
          frequency: frequency.value,
          day: day?.label || "",
          quantity: quantity.value,
          wordCount: wordCount?.value || "",
          email: email,
          time: time.value,
          timezone: moment.tz.guess(),
        }
      )
      const session = response.data

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      })

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        setError(true)
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <BookLayout>
      <SEO
        title={`${bookName} by ${bookAuthor}`}
        description={bookDescription}
      ></SEO>
      <div
        css={css`
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 50px;

          text-align: center;
          margin-bottom: 100px;
          margin-top: 30px;
          @media screen and (max-width: 600px) {
            padding-left: 0;
            padding-right: 0;
          }
          .dropdown {
            max-width: 400px;
            margin: 20px auto;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;

            @media screen and (max-width: 450px) {
              margin-left: 30px;
              margin-right: 30px;
            }
          }
        `}
      >
        <h1>{bookName}</h1>
        <h3
          css={css`
            margin: 0 !important;
          `}
        >
          {bookAuthor}
        </h3>
        <img
          css={css`
            margin-top: 20px;
            @media screen and (max-width: 800px) {
              width: 300px;
            }
          `}
          src={bookImage}
        ></img>
        <p
          css={css`
            margin: 0 auto !important;
            margin-top: 20px !important;
            @media screen and (max-width: 800px) {
              font-size: 18px !important;
            }
          `}
        >
          {bookChapterCount} Chapters
        </p>
        <p
          css={css`
            margin: 0 auto !important;
            margin-top: 10px !important;
            @media screen and (max-width: 800px) {
              font-size: 18px !important;
            }
          `}
        >
          {bookWordCount.toLocaleString()} Words
        </p>

        <p
          css={css`
            white-space: pre-wrap;
            text-align: justify;
            max-width: 800px !important;
            margin: 25px auto !important;
            @media screen and (max-width: 800px) {
              text-align: left;
              font-size: 18px !important;
              padding: 0 30px;
            }
          `}
        >
          {bookDescription}
        </p>

        {/* <p>Word Count: 10,000</p> */}
        <h3>Order Book</h3>
        <Select
          className="dropdown"
          placeholder="Select inbox"
          options={inboxOptions}
          value={inbox}
          onChange={inbox => setInbox(inbox)}
        />
        <Select
          className="dropdown"
          placeholder="Select quantity"
          options={quantityOptions}
          value={quantity}
          onChange={quantity => setQuantity(quantity)}
        />

        {quantity?.value === "wordcount" && (
          <Select
            className="dropdown"
            placeholder="Select word count"
            options={wordCountOptions}
            value={wordCount}
            onChange={wordCount => setWordCount(wordCount)}
          />
        )}
        <Select
          className="dropdown"
          placeholder="Select frequency"
          options={frequencyOptions}
          value={frequency}
          onChange={frequency => setFrequency(frequency)}
        />
        {(frequency?.value === "weekly" || frequency?.value === "monthly") && (
          <Select
            className="dropdown"
            placeholder="Select day"
            options={dayOptions}
            value={day}
            onChange={day => setDay(day)}
          />
        )}
        <Select
          className="dropdown"
          placeholder="Select time"
          options={timeOptions}
          value={time}
          onChange={time => setTime(time)}
        />
        <div>
          <input
            className="dropdown"
            type="email"
            placeholder="jane@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            css={css`
              padding: 5px 10px;
              margin-top: 5px !important;
            `}
          ></input>
        </div>
        {frequency && quantity && !rateString.includes("NaN") && (
          <p
            css={css`
              text-align: center !important;
              margin: 0 auto !important;
            `}
          >
            At this rate you'll finish the book in {rateString}.
          </p>
        )}

        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <button
            css={css`
              background: #000;
              color: #fafafa;
              border: 0;
              padding: 10px;
              font-size: 18px;
              cursor: pointer;
              margin-top: 20px;
              font-family: "Montserrat", sans-serif;
            `}
            role="link"
            onClick={handleClick}
          >
            Buy {bookHasCustomPrice ? "$15.00" : "$4.99"}
          </button>
        )}
        {error && (
          <h3
            css={css`
              color: red;
            `}
          >
            Error: Invalid Data
          </h3>
        )}
      </div>
    </BookLayout>
  )
}

export default BookPage
